<template>
  <div class="dp-capsule">
    <DpPageLoading v-if="isLoading" :is-loading="isLoading" />

    <div :style="{ opacity: !isLoading ? 1 : 0 }">
      <p class="dp-capsule-lead">{{ WORDS.DRIP_POD_CAPSULE.LEAD }}</p>

      <div
        v-if="hasRecentContents || hasRecommendContents"
        class="dp-capsule-head">
        <DpSection
          v-if="hasRecentContents"
          title-en-size="lg"
          :title-en="WORDS.DRIP_POD_CAPSULE.RECENT.EN"
          :title-jp="WORDS.DRIP_POD_CAPSULE.RECENT.JP">
          <DpCapsuleList :contents="recentContents" />
        </DpSection>

        <DpSection
          v-if="hasRecommendContents"
          title-en-size="lg"
          :title-en="WORDS.DRIP_POD_CAPSULE.RECOMMEND.EN"
          :title-jp="WORDS.DRIP_POD_CAPSULE.RECOMMEND.JP">
          <DpCapsuleList :contents="recommendContents" />
        </DpSection>
      </div>

      <div v-if="capsuleContents" class="dp-capsule-taste">
        <DpSection
          title-en-size="lg"
          :title-en="WORDS.DRIP_POD_CAPSULE.TASTE.EN"
          :title-jp="WORDS.DRIP_POD_CAPSULE.TASTE.JP">
          <template v-for="(capsuleContent, key, index) in capsuleContents">
            <DpSection
              v-if="capsuleContent.length"
              :key="index"
              :title-en="getTasteTitleEn(key)"
              :taste="key">
              <DpCapsuleList :contents="capsuleContent" />
            </DpSection>
          </template>
        </DpSection>
      </div>

      <DpCapsuleDetail />
    </div>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { SystemDialogMessage } from '@/constants/enums';
import {
  defineComponent,
  ref,
  provide,
  readonly,
  computed,
  onMounted
} from '@vue/composition-api';
import { ProRecipeTasteType } from '@/constants/enums/drip-pod/ProRecipeTasteType';
import { usePublishable } from '@/composables/usePublishable';

export default defineComponent({
  components: {
    DpPageLoading: () => import('@/components/dripPod/core/PageLoading.vue'),
    DpSection: () => import('@/components/dripPod/core/Section.vue'),
    DpCapsuleList: () => import('@/components/dripPod/capsule/List.vue'),
    DpCapsuleDetail: () => import('@/components/dripPod/capsule/Detail.vue')
  },

  setup: (props, context) => {
    const { getListPublishableContents } = usePublishable();
    const recentContents = ref(undefined);
    const recommendContents = ref(undefined);
    const capsuleContents = ref(undefined);
    const selectedCapsule = ref(undefined);
    const isLoading = ref(false);

    const hasRecentContents = computed(
      () => recentContents.value && recentContents.value?.length
    );
    const hasRecommendContents = computed(
      () => recommendContents.value && recommendContents.value?.length
    );

    const sleep = (ms = 550) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    const getTasteTitleEn = (typeId) =>
      ProRecipeTasteType.getTypeLabelEn(typeId);

    const activateSelectedCapsuleDetail = (capsule) => {
      selectedCapsule.value = capsule;
    };

    const deactivateSelectedCapsuleDetail = () => {
      selectedCapsule.value = undefined;
    };

    provide('activateSelectedCapsuleDetail', activateSelectedCapsuleDetail);
    provide('deactivateSelectedCapsuleDetail', deactivateSelectedCapsuleDetail);
    provide('selectedCapsule', readonly(selectedCapsule));

    const fetchCapsuleList = async () => {
      try {
        context.root.$_loading_start();
        const { data } = await context.root
          .$repositories('dpCapsule')
          .getList();

        recentContents.value = getListPublishableContents(data?.recent);

        recommendContents.value = getListPublishableContents(data?.recommend);

        capsuleContents.value = {
          E: data?.capsules.E
            ? getListPublishableContents(data?.capsules.E)
            : [],

          A: data?.capsules.A
            ? getListPublishableContents(data?.capsules.A)
            : [],

          B: data?.capsules.B
            ? getListPublishableContents(data?.capsules.B)
            : [],

          C: data?.capsules.C
            ? getListPublishableContents(data?.capsules.C)
            : [],

          D: data?.capsules.D
            ? getListPublishableContents(data?.capsules.D)
            : []
        };
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    isLoading.value = true;
    fetchCapsuleList();

    onMounted(async () => {
      // 擬似的なローディング
      await sleep();
      isLoading.value = false;
    });

    return {
      WORDS,
      getTasteTitleEn,
      hasRecentContents,
      hasRecommendContents,
      recentContents,
      recommendContents,
      capsuleContents,
      selectedCapsule,
      isLoading
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-capsule {
  @include mixin.dpDefaultText;
}

.dp-capsule-lead {
  margin-bottom: 0;
  padding: 32px 16px;
  font-size: 14px;
  text-align: center;
}

.dp-capsule-head {
  margin-bottom: 48px;
  border-bottom: 1px solid variables.$dpGreyEa;

  > * {
    padding-bottom: 40px !important;
  }
}

.dp-capsule-taste {
  > * {
    > * {
      padding-bottom: 24px !important;
    }
  }
}
</style>
